import styled, { keyframes } from "styled-components";
import { Explode, FadeOut, GlassEffect, NeonGlow } from "../../styles/effects";
import { sc } from "../../styles/sharedStyles";

export const GameContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "Gabarito", sans-serif;

  .add-hint {
    background: black;
    color: #a8e873;
    cursor: pointer;
    display: flex;
    bottom: 5vh;
    font-size: 2.5vh;
    font-weight: bold;
    position: absolute;
    align-items: center;
    justify-content: space-around;
    right: 4vh;
    width: 4vh;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid rgb(60, 160, 122);

    &:hover {
      text-shadow: rgba(72, 208, 151, 0.8) 0 0 1vw;
      color: #add252;
    }
  }

  .explode {
    position: absolute;
    top: 0;
    left: 12vw;
    width: 80vw;
    height: 100vh;
    opacity: 0.9;
    transform: scale(0.6);
  }
`;

export const Splash = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    font-family: "MedievalSharp", cursive;
    font-size: 17vw;
    font-weight: 1000;
    letter-spacing: 0.5vw;
    color: #a8e873;
    text-align: center;
    margin-top: 20vw;
    margin-bottom: 0vw;
    padding: 0;
    width: 100%;
    ${NeonGlow("rgba(163,224,112, 0.2)", 0.3, true, false)};
  }
  img {
    opacity: 0.3;
    position: absolute;
    width: 150vw;
    height: 150vw;
  }
  button {
    margin-top: 5vw;
    cursor: pointer;
  }
`;

export const Rack = styled.div`
  color: #ffffff;
  font-size: 6vw;
  font-family: Gabarito, sans-serif;
  font-weight: 700;
  line-height: 10vw;
  position: absolute;
  top: 85vw;
  width: 100vw;
  text-align: center;
  text-shadow: rgba(72, 208, 151, 0.8) 0 0 2vw;

  span {
    display: inline-flex;
    align-content: center;
    justify-content: center;
    min-width: 10.5vh;
    margin: 0;
    user-select: none;
    text-align: center;
    aspect-ratio: 1;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
  }
`;
export const SummaryTitle = styled.h2`
  font-family: "MedievalSharp", cursive;
  font-size: 8.8vw;
  font-weight: 200;
  letter-spacing: 0.5vw;
  text-align: center;
  position: absolute;
  top: 1vw;
  width: 100vw;
  &.success {
    color: #a8e873;
  }

  &.fail {
    color: #d24848;
  }
`;

export const Attempt = styled.div`
  color: #ffffff;
  background: rgba(0, 0, 0, 0.66);
  font-size: 7vw;
  font-family: Gabarito, sans-serif;
  font-weight: 300;
  line-height: 10vw;
  position: absolute;
  letter-spacing: 1vw;
  top: 73vw;
  width: 40vw;
  left: 30vw;
  border-radius: 10vw;
  text-align: center;
  border: 0.3vw solid #93be82;
`;

export const Hint = styled.div`
  color: #ffffff;
  opacity: 0.5;
  font-size: 7vw;
  font-family: Gabarito, sans-serif;
  font-weight: 300;
  line-height: 10vw;
  position: absolute;
  letter-spacing: 1vw;
  top: 73vw;
  width: 40vw;
  left: 30vw;
  text-align: center;
`;

export const GoalContainer = styled.div`
  display: flex;
  position: absolute;
  top: 5vw;
  left: 0;
  flex-wrap: wrap;
  column-gap: 30vw;
  row-gap: 1vw;
  width: 88vw;
  justify-content: space-between;
  padding: 1vw 6vw;
  align-items: center;
`;

export const SummaryContainer = styled.div`
  display: flex;
  position: absolute;
  top: 30vw;
  left: 0;
  flex-wrap: wrap;
  row-gap: 1vw;
  width: 88vw;
  height: 100vw;
  justify-content: space-between;
  padding: 1vw 6vw;
  align-items: center;
  .totals {
    color: white;
    opacity: 0.6;
    position: absolute;
    top: 64vw;
    right: 8vw;
  }
  h2 {
    position: relative;
    top: -10vw;
  }
`;

export const WordList = styled.div`
  display: flex;
  width: 80vw;
  flex-wrap: wrap;
  gap: 2vw;
  position: absolute;
  top: 3vw;
  left: 7vw;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
`;

export const Goal = styled.div`
  display: block;
  border-radius: 3vw;
  border: 0.3vw solid rgba(0, 0, 0, 0.23);
  background: rgba(0, 0, 0, 0.13);
  width: 15vw;
  height: 4vw;
`;

export const GoalFound = styled.div`
  color: white;
  display: flex;
  border-radius: 3vw;
  border: 0.3vw solid #93be82;
  background: rgba(0, 0, 0, 0.52);
  font-size: 3vw;
  width: 15vw;
  height: 4vw;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4vw;
`;
export const GoalMissed = styled.div`
  color: #efafaf;
  display: flex;
  border-radius: 3vw;
  border: 0.3vw solid #de413f;
  background: rgba(0, 0, 0, 0.52);
  font-size: 3vw;
  width: 15vw;
  height: 4vw;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4vw;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3vw;
  position: absolute;
  right: 4vw;
  width: 30vw;
  padding: 3vw;
`;

export const Button = styled.button`
  border-radius: 100px;
  color: #bfecb0;
  font-family: Montserrat;
  padding: 0.75em 1.5em;
  box-shadow: 0 1vh 0.2vh 0 rgba(56, 16, 59, 0.2);
  font-size: 3vh;
  ${GlassEffect("rgb(143,184,126)", 0.3)}
  border: 0.1vh solid rgb(138, 178, 124);

  &:hover {
    ${GlassEffect("rgb(36,87,18)", 0.1)}
    border: 0.1vh solid rgb(134, 172, 121);
    color: white;
  }
`;

export const ButtonSecondary = styled.button`
  border-radius: 100px;
  color: #eedea9;
  font-family: Montserrat;
  padding: 0.75em 1.5em;
  box-shadow: 0 1vh 0.2vh 0 rgba(56, 16, 59, 0.2);
  font-size: 3vh;
  ${GlassEffect("rgb(190,176,108)", 0.3)}
  border: 0.1vh solid rgb(178, 159, 124);

  &:hover {
    ${GlassEffect("rgb(255,181,29)", 0.1)}
    border: 0.1vh solid rgb(206, 172, 110);
    color: white;
  }
`;

export const HitPoints = styled.div`
  position: absolute;
  display: flex;
  top: 7vw;
  left: 35vw;
  width: 30vw;
  flex-direction: row;
  height: 1vw;

  div {
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: row;
    height: 1.5vw;
    border-radius: 2vw;
  }

  .hp span {
    display: inline-flex;
    width: 100%;
    margin: 0;

    &.life {
      background: #81746b;
    }

    &.damage {
      background: transparent;
    }

    &:nth-child(1) {
      border-radius: 2vw 0 0 2vw;
    }

    &:last-child {
      border-radius: 0 2vw 2vw 0;
    }
  }

  .bg {
    background: black;
    width: 100%;
  }
`;

export const LifeContainer = styled.div`
  display: flex;
  position: absolute;
  top: 76vw;
  right: 6vw;
  ${NeonGlow("rgba(0,0,0,0.35)", 0.3, true, false)}

  span {
    display: block;
    font-size: 3vw;
    margin-left: 1vw;
  }
`;

export const Scoreboard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 40vw;
  position: absolute;
  top: 40vw;
  left: 5vw;
  padding: 5vw;
  background: rgba(0, 0, 0, 0.45);
  border-radius: 0 0 0 5vw;
  font-size: 4vw;
  justify-content: flex-end;
  align-content: flex-end;
  gap: 0;
  border: solid #a8e873 1px;

  h3,
  h4 {
    flex-basis: 50%;
    margin: 0;
    font-size: 3vw;
    color: #a8e873;
  }
  h4 {
    font-weight: 200;
  }
  h3 {
    line-height: 6vw;
  }
  p {
    margin: 0;
    width: 20vw;
    flex-basis: 50%;
    text-align: right;
    color: #a8e873;

    font-size: 3vw;
    &.total {
      font-size: 4vw;
      color: white;
      font-weight: 800;
      line-height: 6vw;
    }
  }

  h3 {
  }
`;

export const Title = styled.div`
  font-family: "MedievalSharp", cursive;
  font-size: 5.8vw;
  font-weight: 1000;
  letter-spacing: 0.5vw;
  color: #a8e873;
`;

export const Fail = styled.div`
  font-family: Gabarito, sans-serif;
  font-size: 6vw;
  letter-spacing: 1vw;
  font-weight: 800;
  position: absolute;
  color: #ffffff;
  text-shadow: rgba(210, 9, 9, 0.87) 0 0 10vw, rgb(234, 176, 100) 0 0 2vw,
    rgb(0, 0, 0) 0 0 2vw;
  top: 14vw;
  text-align: center;
  width: 100vw;
`;

export const Correct = styled.div`
  font-family: Gabarito, sans-serif;
  font-size: 12vw;
  font-weight: 1000;
  letter-spacing: 1vw;
  position: absolute;
  color: #000000;
  text-shadow: rgba(243, 199, 149, 0.8) 0 0 2vw, rgb(252, 216, 75) 0 0 1vw,
    rgb(255, 255, 255) 0 0 2vw;
  top: 40vw;
  text-align: center;
  width: 35vw;
  height: 15vw;
  display: flex;
  left: 33vw;
  justify-content: center;
  align-items: center;
`;

export const RackSummary = styled.div`
  color: #ffffff;
  font-size: 6vw;
  font-family: Gabarito, sans-serif;
  font-weight: 700;
  line-height: 10vw;
  position: absolute;

  top: 19vw;
  width: 100vw;
  text-align: center;
  text-shadow: rgba(72, 208, 151, 0.8) 0 0 2vw;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 5vh;
    margin: 0;
    user-select: none;
    text-align: left;
  }
`;
