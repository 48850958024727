import { twos } from "../../data/2";
import { temp } from "../../data/34innerdata";
import { new24 } from "../../data/new24";

export const logDebug = (message: string) => {
  console.log(`%c ${message}`, "background: #222; color: #FF88AA");
};

export const lines = (someText: string = temp) =>
  someText
    .trim()
    .split(/\r?\n/)
    .filter((x) => x.length > 0);

export const getWordsByLength = (len: number) => {
  return lines()
    .filter((l) => l.length === 2 + len)
    .map((l) => l.substring(1, l.length - 1));
};

export const getNewWords = () => {
  return lines(new24);
};

export const alphaSort = (a: string, b: string) =>
  a.charCodeAt(0) - b.charCodeAt(0);

export const sortByAlphagram = (a: string, b: string): number => {
  const alphaA = a.split("").sort().join("");
  const alphaB = b.split("").sort().join("");
  return alphaA.localeCompare(alphaB);
};

export const distribution =
  "AAAAAAAAAEEEEEEEEEEEEIIIIIIIIIOOOOOOOOUUUUBBCCDDDDFFGGGHHJKLLLLMMNNNNNNPPQRRRRRRSSSSTTTTTTVVWWXYYZ";

export const drawTiles = (count: number, bag = distribution) => {
  let tiles = bag.split("");
  let rack = [];
  for (let i = 0; i < count; i++) {
    const x = Math.floor(Math.random() * tiles.length);
    rack.push(tiles[x]);
    tiles.splice(x, 1);
  }
  return rack;
};

// Function to check if a word can be formed from the rack
const canFormWord = (word: string, rack: string | string[]): boolean => {
  let tempRack = typeof rack === "string" ? rack : rack.join("");
  for (const char of word) {
    if (!tempRack.includes(char)) {
      return false;
    }
    tempRack = tempRack.replace(char, "");
  }
  return true;
};

export const findAllWords = (
  rack: string | string[],
  words: string[]
): string[] => {
  // Filter words array to include only those that can be formed from the rack
  return words.filter(
    (word) => word.length <= rack.length && canFormWord(word, rack)
  );
};

// ChatGPT helped with this! It's related to a classic combinatorial optimization
// problem, and is nontrivial. As a result, we have to cut however many words we're
// given down to a manageable length. Else browser angry.
export const generateRack = (
  wordsToSee: string[],
  maxLength: number,
  maxWords: number = Infinity,
  allWords = getWordsByLength(3),
  excludedRacks: Set<string> = new Set<string>()
): string => {
  const arrayLimit = Math.floor(15 / maxLength);
  // Function to get all combinations of a given array of characters
  const getCombinations = (chars: string[], length: number): string[] => {
    if (length === 1) return chars;
    let result: string[] = [];

    chars.forEach((char, i) => {
      const remainingChars = chars.slice(i + 1);
      const combinations = getCombinations(remainingChars, length - 1);
      combinations.forEach((combination) => {
        result.push(char + combination);
      });
    });

    return result;
  };

  const filterLetters = (letterList: string, words: string[]): string => {
    const letterCount: { [key: string]: number } = {};
    const requiredCount: { [key: string]: number } = {};

    // Count the frequency of each letter in the letter list
    for (const letter of letterList) {
      letterCount[letter] = (letterCount[letter] || 0) + 1;
    }

    // Count the maximum frequency required for each letter in the words
    for (const word of words) {
      const wordLetterCount: { [key: string]: number } = {};
      for (const letter of word) {
        wordLetterCount[letter] = (wordLetterCount[letter] || 0) + 1;
        requiredCount[letter] = Math.max(
          requiredCount[letter] || 0,
          wordLetterCount[letter]
        );
      }
    }

    // Construct the minimal list of letters needed,
    // starting with enough to make at least the first word
    let result = "";

    for (const [letter, maxCount] of Object.entries(requiredCount)) {
      const count = Math.min(maxCount, letterCount[letter] || 0);
      result += letter.repeat(count);
    }

    return result;
  };
  // Since we can't deal with very many words at once we'll grab a
  // randomish subset from what we're given
  let wordsToSeeRNGSlice: string[] = [];
  if (wordsToSee.length > arrayLimit) {
    for (let i = 0; i < arrayLimit; i++) {
      const index = Math.random() * (wordsToSee.length - 1);
      wordsToSeeRNGSlice.push(wordsToSee[Math.floor(Math.random() * index)]);
    }
  } else {
    wordsToSeeRNGSlice = wordsToSeeRNGSlice.concat(wordsToSee);
  }

  // Flatten wordsToSee into a list of characters
  let rngLetters = filterLetters(
    wordsToSeeRNGSlice.join(""),
    wordsToSeeRNGSlice
  );

  const minLetters = filterLetters(wordsToSeeRNGSlice[0], wordsToSeeRNGSlice);

  // Keep track of the best rack found and the number of words it can make
  let bestRack = "";
  let bestCount = 0;

  // Try combinations of increasing lengths
  for (let len = maxLength; len >= 0; len--) {
    const combinations = getCombinations(rngLetters.split(""), len).filter(
      (combination) => !excludedRacks.has(combination)
    );
    combinations.forEach((combination) => {
      const foundInRemainging = findAllWords(combination, wordsToSee);
      if (foundInRemainging.length) {
        let found = findAllWords(combination, allWords);
        const count = found.length;
        if (count <= maxWords && count > bestCount) {
          bestRack = combination;
          bestCount = count;
        }
      }
    });
    if (bestCount > 0) {
      break;
    }
  }

  return bestRack;
};

export const signedRandom = (range: number, minabs: number = 0) => {
  const sign = Math.round(Math.random()) * 2 - 1;
  return sign * (minabs + Math.round(Math.random() * range - minabs));
};

// This is inefficient for large arrays
export const shuffle = <T>(array: T[]): T[] => {
  const copy = [...array];
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copy[i], copy[j]] = [copy[j], copy[i]];
  }
  return copy;
};

// Remove words with duplicate letters
export const removeDuplicates = (words: string[]) =>
  words.filter((w) => new Set(w.split("")).size === w.length);

// analytics
export const sendEvent = (eventName: string, data: any) => {
  // @ts-ignore
  window.gtag("event", eventName, data);
};

export const countOccurrences = (
  arr: string[],
  insensitive = false
): Record<string, number> =>
  arr.reduce((acc: Record<string, number>, str: string) => {
    const key = insensitive ? str.toLowerCase() : str;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

export const filterToUnique = <T>(arr: T[]): T[] =>
  arr.filter((item) => arr.indexOf(item) === arr.lastIndexOf(item));

export const findUniqueMatches = (
  word: string,
  wordList: string[] = twos,
  rightSide: boolean
) => {
  const tiles = word.split("");
  const lettersUsed = new Array<string>();
  tiles.forEach((tile) => {
    const words = wordList.filter(
      (w) => w.split("")[rightSide ? 0 : 1] === tile
    );
    words.forEach((w) => {
      lettersUsed.push(w.split("")[rightSide ? 1 : 0]);
    });
  });
  const uniqueUsed = filterToUnique(lettersUsed);
  return uniqueUsed;
};

// Return a randomish thing from a collection, ignoring the ones in exclude if it exists
export const randomSelect = <T>(
  items: T[] | Set<T>,
  exclude?: T[] | Set<T>
): T | undefined => {
  const arr =
    items instanceof Array
      ? [...items]
      : Array.from(items).filter((i) => !new Set(exclude).has(i));
  if (arr.length === 0) return undefined;
  return arr[Math.floor(Math.random() * arr.length)];
};
